export default {
  state: {
    cart: [],
  },
  mutations: {
    ADD_CART_ITEMS: (state, order) => {
      state.cart.push(order)
    },
    CLEAR_CART: state => {
      state.cart = []
    },
    REMOVE_CART_ITEM: (state, id) => {
      const index = state.cart.findIndex(item => item.id === id)
      state.cart.splice(index, 1)
    },
  },
  getters: {
    getCartItems: state => state.cart,
    getCartItemsLength: state => state.cart.length,
  },
  actions: {
    addToCart({ state, commit }, { order }) {
      try {
        const checkItemIfExisting = state.cart.find(item => item.id === order.id)
        if (!checkItemIfExisting) {
          commit('ADD_CART_ITEMS', order)
          return true
        }
        return false
      } catch (e) {
        console.error(e)
        return false
      }
    },
    clearCart({ commit }) {
      commit('CLEAR_CART')
    },
    removeCartItem({ commit }, id) {
      commit('REMOVE_CART_ITEM', id)
    },
  },
}
